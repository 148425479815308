import { twMerge } from "tailwind-merge";

const BashawayPortal = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74 30"
      fill="none"
      className={twMerge("w-[74px] h-[30px]", className)}
      {...props}
    >
      <path
        d="M0.0478516 29.341V6.09095H3.96689V8.88712H4.19806C4.40356 8.47613 4.69345 8.03946 5.06774 7.5771C5.44203 7.10741 5.94842 6.70743 6.58691 6.37717C7.22541 6.03958 8.04004 5.87078 9.03081 5.87078C10.3372 5.87078 11.5151 6.20471 12.5645 6.87256C13.6214 7.53307 14.458 8.51283 15.0745 9.81183C15.6983 11.1035 16.0102 12.6887 16.0102 14.5675C16.0102 16.4243 15.7056 18.0022 15.0965 19.3012C14.4874 20.6002 13.6581 21.591 12.6086 22.2735C11.5591 22.956 10.3702 23.2973 9.04181 23.2973C8.07306 23.2973 7.26944 23.1358 6.63095 22.8129C5.99245 22.49 5.47872 22.101 5.08976 21.646C4.70813 21.1836 4.4109 20.747 4.19806 20.336H4.03294V29.341H0.0478516ZM3.95588 14.5455C3.95588 15.639 4.11 16.5968 4.41823 17.4187C4.73381 18.2407 5.18516 18.8829 5.77228 19.3452C6.36674 19.8002 7.08597 20.0277 7.92995 20.0277C8.81064 20.0277 9.54821 19.7929 10.1427 19.3232C10.7371 18.8462 11.1848 18.1967 11.4857 17.3747C11.7939 16.5454 11.9481 15.6023 11.9481 14.5455C11.9481 13.496 11.7976 12.564 11.4967 11.7493C11.1958 10.9347 10.7481 10.2962 10.1537 9.83385C9.55922 9.37149 8.81797 9.14031 7.92995 9.14031C7.07863 9.14031 6.35574 9.36415 5.76128 9.81183C5.16682 10.2595 4.71547 10.887 4.40723 11.6943C4.10633 12.5016 3.95588 13.452 3.95588 14.5455Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M24.4736 23.3303C22.8223 23.3303 21.3912 22.967 20.1802 22.2405C18.9693 21.5139 18.0299 20.4974 17.3621 19.1911C16.7015 17.8848 16.3713 16.3582 16.3713 14.6116C16.3713 12.8649 16.7015 11.3347 17.3621 10.021C18.0299 8.70731 18.9693 7.68719 20.1802 6.96063C21.3912 6.23407 22.8223 5.87078 24.4736 5.87078C26.1248 5.87078 27.5559 6.23407 28.7669 6.96063C29.9778 7.68719 30.9135 8.70731 31.5741 10.021C32.2419 11.3347 32.5758 12.8649 32.5758 14.6116C32.5758 16.3582 32.2419 17.8848 31.5741 19.1911C30.9135 20.4974 29.9778 21.5139 28.7669 22.2405C27.5559 22.967 26.1248 23.3303 24.4736 23.3303ZM24.4956 20.1378C25.3909 20.1378 26.1395 19.892 26.7413 19.4003C27.3431 18.9012 27.7908 18.2334 28.0844 17.3967C28.3853 16.5601 28.5357 15.628 28.5357 14.6005C28.5357 13.5657 28.3853 12.63 28.0844 11.7934C27.7908 10.9494 27.3431 10.2779 26.7413 9.77881C26.1395 9.27976 25.3909 9.03023 24.4956 9.03023C23.5782 9.03023 22.8149 9.27976 22.2058 9.77881C21.604 10.2779 21.1527 10.9494 20.8518 11.7934C20.5582 12.63 20.4114 13.5657 20.4114 14.6005C20.4114 15.628 20.5582 16.5601 20.8518 17.3967C21.1527 18.2334 21.604 18.9012 22.2058 19.4003C22.8149 19.892 23.5782 20.1378 24.4956 20.1378Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M33.6332 23V6.09095H37.4972V8.90913H37.6733C37.9816 7.93305 38.51 7.1808 39.2586 6.65239C40.0145 6.11664 40.8768 5.84877 41.8456 5.84877C42.0657 5.84877 42.3116 5.85977 42.5831 5.88179C42.862 5.89647 43.0932 5.92216 43.2767 5.95885V9.62469C43.1079 9.56598 42.84 9.5146 42.473 9.47057C42.1134 9.4192 41.7648 9.39351 41.4272 9.39351C40.7007 9.39351 40.0475 9.5513 39.4677 9.86688C38.8953 10.1751 38.4439 10.6044 38.1137 11.1549C37.7834 11.7053 37.6183 12.3401 37.6183 13.0593V23H33.6332Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M52.951 6.09095V9.17334H43.2304V6.09095H52.951ZM45.6303 2.03982H49.6154V17.9141C49.6154 18.4499 49.6961 18.8608 49.8576 19.1471C50.0264 19.4259 50.2465 19.6168 50.5181 19.7195C50.7896 19.8223 51.0905 19.8736 51.4208 19.8736C51.6703 19.8736 51.8978 19.8553 52.1033 19.8186C52.3161 19.7819 52.4776 19.7489 52.5877 19.7195L53.2592 22.8349C53.0464 22.9083 52.7418 22.989 52.3455 23.0771C51.9565 23.1652 51.4795 23.2165 50.9144 23.2312C49.9163 23.2606 49.0173 23.1101 48.2173 22.7799C47.4173 22.4423 46.7825 21.9212 46.3128 21.2167C45.8505 20.5121 45.623 19.6314 45.6303 18.5746V2.03982Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M58.8246 23.3413C57.7531 23.3413 56.788 23.1505 55.9293 22.7689C55.078 22.3799 54.4028 21.8075 53.9037 21.0515C53.412 20.2956 53.1662 19.3636 53.1662 18.2554C53.1662 17.3013 53.3423 16.5124 53.6946 15.8885C54.0469 15.2647 54.5276 14.7657 55.1367 14.3914C55.7458 14.0171 56.432 13.7345 57.1953 13.5437C57.9659 13.3456 58.7622 13.2025 59.5841 13.1144C60.5749 13.0116 61.3785 12.9199 61.995 12.8392C62.6115 12.7511 63.0592 12.619 63.3381 12.4429C63.6243 12.2594 63.7674 11.9768 63.7674 11.5952V11.5292C63.7674 10.6999 63.5215 10.0577 63.0298 9.60267C62.5381 9.14765 61.8299 8.92014 60.9052 8.92014C59.9291 8.92014 59.1548 9.13298 58.5824 9.55864C58.0173 9.9843 57.6356 10.487 57.4375 11.0668L53.7166 10.5384C54.0102 9.51093 54.4945 8.65227 55.1697 7.9624C55.8449 7.2652 56.6706 6.74412 57.6466 6.39919C58.6227 6.04692 59.7016 5.87078 60.8832 5.87078C61.6978 5.87078 62.5087 5.96619 63.316 6.157C64.1233 6.34782 64.8609 6.6634 65.5287 7.10374C66.1966 7.53674 66.7323 8.12753 67.136 8.87611C67.547 9.62469 67.7525 10.5604 67.7525 11.6833V23H63.9215V20.6772H63.7894C63.5472 21.1469 63.206 21.5873 62.7656 21.9983C62.3326 22.4019 61.7859 22.7285 61.1253 22.978C60.4722 23.2202 59.7052 23.3413 58.8246 23.3413ZM59.8594 20.413C60.6593 20.413 61.3528 20.2553 61.94 19.9397C62.5271 19.6168 62.9784 19.1911 63.294 18.6627C63.6169 18.1343 63.7784 17.5582 63.7784 16.9343V14.9418C63.6536 15.0446 63.4408 15.14 63.1399 15.228C62.8463 15.3161 62.5161 15.3932 62.1491 15.4592C61.7822 15.5253 61.4189 15.584 61.0593 15.6353C60.6997 15.6867 60.3878 15.7308 60.1236 15.7674C59.5291 15.8482 58.997 15.9803 58.5273 16.1638C58.0576 16.3472 57.687 16.6041 57.4155 16.9343C57.1439 17.2573 57.0082 17.6756 57.0082 18.1893C57.0082 18.9232 57.276 19.4773 57.8118 19.8516C58.3475 20.2259 59.0301 20.413 59.8594 20.413Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path d="M73.4197 0.45459V23H69.4346V0.45459H73.4197Z" fill="black" fillOpacity="0.2" />
    </svg>
  );
};

export default BashawayPortal;
